.Branding-page{
    border-top: 0.5px solid #E5E5E5;

padding-left: 4%;
display: flex;
    width: 100%;
    height: calc(100vh - 73px);
    overflow: hidden;
    .sub{
        padding-top: 3rem;
    }
.item-list-section{
height: 100%;
padding-left: 4%;
padding-right: 4%;
border-left: 0.5px solid #E5E5E5;

    .landing-section-sub{
   height: 100%;
   padding-bottom: 10rem;
        overflow: scroll;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      
        &::-webkit-scrollbar{
            display: none;
        }
    .cards{
        height: 251px;
        background: #FFFFFF;
        border: 0.5px solid #E5E5E5;
        border-radius: 15px 15px 15px 15px;
        width: 32%;
     margin-bottom: 2%;
        display: inline-block;
        .imglist
        {
            height: 171.78px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    
       h3{
        font-style: normal;
        font-weight: 800;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;
        margin-bottom: 2px;
        color: #18191D;
       }
    p{
        font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 11px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    color: #18191D;
    }
    
    .label-first{
        font-style: normal;
    font-weight: bold;
    font-size: 8px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    width: 56.47px;
    height: 19.92px;
    left: 295px;
    top: 822.92px;
    justify-content: center;
    background: #FFCA05;
    border-radius: 3px;
    
    color: #FFFFFF;
    }
    
    .label-last{
        font-style: normal;
    font-weight: bold;
    font-size: 8px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    width: 56.47px;
    height: 19.92px;
    left: 295px;
    top: 822.92px;
    justify-content: center;
    background: #F45D48;
    border-radius: 3px;
    margin-left: 6px;
    color: #FFFFFF;
    }
    
    .content-section{
        display: flex;
        justify-content: space-between;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        padding-left: 30px;
        padding-right: 30px;
    .labelc-content{
        display: flex;
        align-items: center;
    }
    
    }
    
    
    
    
    }
    }


    .content{
        display:flex;
        align-items: center;
justify-content: space-between;

margin-bottom: 2rem;

        sub{
          color: #18191D; 
            }
            h1{
                font-style: normal;
font-weight: 800;
font-size: 30px;
line-height: 37px;
display: flex;
align-items: center;

color: #18191D;

            }
            p{
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 18px;
                display: flex;
                align-items: center;
                
           
        }

        
        .search-section{
            position: relative;
            background: #FFFFFF;
border: 0.5px solid #EBEBEB;
border-radius: 10px;
width: 291px;
height: 47px;
margin-top: 1rem;
margin-bottom: 2rem;

            img{
                position: absolute;
                top: 16px;
                left: 13px;
            }
            input{
                font-style: normal;
font-weight: normal;
font-size: 12px;
padding-left: 3rem;
line-height: 35px;
/* or 292% */
border: none;
height: 100%;
width: 100%;

display: flex;
align-items: center;

color: #182542;

opacity: 0.45;
&:focus-visible {
    outline: -webkit-focus-ring-color auto 0px !important;
}
            }
        }
    }
}








    .items{
        
        width: 291px;

     .cards{
         display: flex;
         align-items: center;
         width: 291px;
         height: 63px;
         img{
            padding-left: 13px; 
            margin-right: 16px;
         }
         &:hover{
             background: rgba(170, 170, 170, 0.17);
         }
h5{
    font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
display: flex;
align-items: center;
margin-bottom: 0;
color: #18191D;

}
     }
        
    }
    .sub{
        padding-right: 4%;
       
        h2{
            font-style: normal;
font-weight: 800;
font-size: 25px;
line-height: 30px;
display: flex;
align-items: center;

color: #18191D;
        }

        .search-section{
            position: relative;
            background: #FFFFFF;
border: 0.5px solid #EBEBEB;
border-radius: 10px;
width: 291px;
height: 47px;
margin-top: 1rem;
margin-bottom: 2rem;

            img{
                position: absolute;
                top: 16px;
                left: 13px;
            }
            input{
                font-style: normal;
font-weight: normal;
font-size: 12px;
padding-left: 3rem;
line-height: 35px;
/* or 292% */
border: none;
height: 100%;
width: 100%;

display: flex;
align-items: center;

color: #182542;

opacity: 0.45;
&:focus-visible {
    outline: -webkit-focus-ring-color auto 0px !important;
}
            }
        }
    }
}


.modal-content{
    border-radius: 25px;
}
    .modal-body{
 .model-content{
    padding-top: 1rem;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     .info{
        padding-left: 1.8rem;
        margin-top: 3rem;
         .cards{
             display: flex;
         align-items: center;
         background: #FFFFFF;
border: 0.5px solid #E5E5E5;
border-radius: 15px;
width: 401px;
height: 101px;
margin-bottom: 1.5rem;
padding-left: 2rem;    
img{
    margin-right: 1rem;
}     
cursor: pointer;
h3{
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 22px;
            display: flex;
            align-items: center;
            margin-bottom: 0;
            color: #18191D;
            
         }

         p{
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 13px;
            display: flex;
            align-items: center;
            
            color: #18191D;
            
         }
            }
     }
 
     }

    }
    .modal-footer{
        height: 90px;
        background: #5ABEA0;
border: 0.5px solid #5ABEA0;
display: flex;
align-items: center;
justify-content: center;
border-radius: 0px 0px 25px 25px;
h6{
    font-style: normal;
    margin: 0;
font-weight: 800;
font-size: 20px;
line-height: 24px;
display: flex;
align-items: center;
text-align: center;

color: #FFFFFF;

}
 }   
 .modal-backdrop.show {
    opacity: .8;
}