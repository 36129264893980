.homepage{
    height: 100vh;
   
    align-items: center;
    justify-content: center;
    .cutonhome{
        width: 100%;
        height: calc(100vh - 5rem);
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .imgsearc{
        
position: absolute;
right: 3rem;
top: 38px;
    }
    .topinputdiv{

     display: flex;
     align-items: center;
     justify-content: center;   
margin-top: 4rem;
    }
    input{
        width: 807.41px;
        height: 100px;
        left: 316px;
        top: 601px;
        background: #FFFFFF;
        border: 1px solid #E5E5E5;
        border-radius: 15px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #182542;
        opacity: 0.5;
        padding-left: 4%;
  
  
        &:focus{
            outline: -webkit-focus-ring-color auto 0px;
           
          
        }
    }
    }
    .twobuuton
    {
        margin-top: 4rem;
            display: flex;
        align-items: center;
        justify-content: center;
    
    .hlogin{
font-family: Montserrat;
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 27px;
display: flex;
align-items: center;
text-align: center;
width: 270px;
height: 81px;
transition:all ease-in-out 0.30s;
cursor: pointer;
justify-content: center;
background: #FFFFFF;
border: 2px solid #E5E5E5;
border-radius: 5px;
color: #182542;
margin-bottom:0px;
&:hover{
    font-size: 25px;
}
    }

    .hStarted{
 font-family: Montserrat;
font-style: normal;
font-weight: 600;
transition:all ease-in-out 0.30s;
cursor: pointer;
font-size: 22px;
line-height: 27px;
display: flex;
align-items: center;
text-align: center;
width: 270px;
height: 81px;
justify-content: center;
background: #5ABEA0;
border: 2px solid #5ABEA0;
border-radius: 5px;
color: #FFFFFF;
margin-bottom:0px;
margin-left:1rem;
&:hover{
    font-size: 25px;
}
    }
  
}

.bottombrand{
    background: #5ABEA0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5rem;
    p{
        font-family: Montserrat;
font-style: normal;
font-weight: 600;
margin: 0;
font-size: 22px;
line-height: 27px;
display: flex;
align-items: center;
text-align: center;
margin-bottom: 0;
color: #FFFFFF;

    }
    .notshowmobile{
        display: flex;
                }
    label{
        transition:all ease-in-out 0.30s; 
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        border: 2px solid #E5E5E5;
        border-radius: 5px;
        height: 2rem;
        width: 7rem;
        justify-content: center;
        margin-left: 2rem;
        &:hover{
            font-size: 18px;
        }  
        .showmobile{
            display: none;
                                    }     
    }
}

@media only screen and (min-width:0px) and (max-width:600px)
{


    .homepage{
        .logo{
            width: 17rem;
        }
        .bottombrand
        {
            label{
                display: none;
            }
            .notshowmobile{
                display: none;
                        }
                        .showmobile{
display: block;
                        }
        }
      
.topinputdiv{
    width: 100%;
    margin-top: 3rem;
}
.imgsearc {
    position: absolute;
    right: 15%;
    top: 30PX;
}
input{
    width: 80%;
    height: 76px;
    font-size: 14px;
}
.hStarted
{
    width:50%;
    height: 59px;
    font-size: 19px;
}



.twobuuton {
    margin-top: 3rem;
    width: 80%;
}
.hlogin{
    
        width:50%;
        height: 59px;
        font-size: 19px;
    }
}
    

}

@media only screen and (min-width:600px) and (max-width:900px)
{


    .homepage{
        .logo{
            width: 17rem;
        }
        .bottombrand
        {
            label{
                display: none;
            }
            .notshowmobile{
                display: none;
                        }
                        .showmobile{
display: block;
                        }
        }
      
.topinputdiv{
    width: 100%;
    margin-top: 3rem;
}
.imgsearc {
    position: absolute;
    right: 15%;
    top: 30PX;
}
input{
    width: 80%;
    height: 76px;
    font-size: 14px;
}
.hStarted
{
    width:187px;
    height: 59px;
    font-size: 19px;
}



.twobuuton {
    margin-top: 3rem;
   
}
.hlogin{
    
        width:187px;
        height: 59px;
        font-size: 19px;
    }
}
    

}


.SearchBarMainHome{
    height: 100vh;
    .logoSectionMain
    {
        display: flex;
        align-items: center;
        justify-content: center;
        .logo{
            width: 10rem;
        }
        height: 5rem;
    }
   
    .back{
        padding-left: 5%;
        cursor: pointer;
    }
    .HandlingSearch{
        display: flex;
        align-items: center;
        border-top: 0.5px solid #EBEBEB;
        border-bottom: 0.5px solid #EBEBEB;
height: 5rem;    
width: 100%;
position: relative;
div{
    width: 20%;
display: flex;
align-items: center;
justify-content: center;
height: 100%;
border-left: 0.5px solid #EBEBEB;
}
input{
    width: 60%;
    font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 16px;
display: flex;
align-items: center;
padding-left: 2%;
color: #182542;

opacity: 0.5;
border: none;
margin: 0;
height: 100%;
&:focus{
    outline: -webkit-focus-ring-color auto 0px !important;

}
}
    }


    .buttonSection
    {
       
        border-bottom: 0.5px solid #EBEBEB;
height: 5rem;    
width: 100%;
     
            display: flex;
    padding-left: 5%;
            align-items: center;
      
            label{
                width: 121.59px;
                height: 39.61px;
                left: 22px;
                top: 197.3px;
                background: #FFFFFF;
                border: 0.5px solid #E7E7E7;
                display: flex;
                align-items: center;
              
                justify-content: center;
                margin: 0;
                margin-right: 1rem;
                font-family: Montserrat;
                font-style: normal;
           
                font-size: 11px;
                line-height: 13px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #08152D;
                mix-blend-mode: normal;
               
            }
            .true{
                opacity: 1;
                font-weight: bold;
            }
            .false{
                font-weight:normal;
                opacity: 0.5;
            
        }
    }
}