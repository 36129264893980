



.landing-section

{
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 4%;
    .top-title-section{
        display: flex;
        justify-content: space-between;
        padding-bottom: 2rem;
   
        padding-right: 4%;
        .sub-1{
            display: flex;
align-items: center;
        }
        .sub-2{
            display: flex;
        .labletop{

        }
        }
    }









    h1{
        font-style: normal;
font-weight: bold;
font-size: 25px;
line-height: 30px;
display: flex;
align-items: center;
margin-left: 10px;
margin-bottom: 0;
color: #18191D;

    }

    h5{
        font-style: normal;
font-weight: bold;
font-size: 13px;
line-height: 40px;
/* or 308% */

display: flex;
align-items: center;
text-align: right;

color: #18191D;

    }


    .labletop{
        left: 88.06%;
right: 5.42%;
top: 62.22%;
bottom: 34.33%;
width: 88px;
background: #FFFFFF;
border: 1px solid #EBEBEB;
border-radius: 5px;
        font-style: normal;
font-weight: bold;
font-size: 13px;
line-height: 35px;
/* or 269% */
justify-content: center;
display: flex;
align-items: center;
text-align: center;
margin-left: 10px;
margin-bottom: 0px;
color: #18191D;
height: 37px;
img{
    margin-right: 6px;
}
    }
    
    
    .landing-section-sub{
   
    overflow: scroll;

    white-space: nowrap;
    &::-webkit-scrollbar{
        display: none;
    }
.cards{
    height: 251px;
    background: #FFFFFF;
    border: 0.5px solid #E5E5E5;
    border-radius: 15px 15px 15px 15px;
    width: 403px;
    margin-right: 30px;
    display: inline-block;
    .imglist
    {
        height: 171.78px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

   h3{
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    color: #18191D;
   }
p{
    font-style: normal;
font-weight: normal;
font-size: 9px;
line-height: 11px;
display: flex;
align-items: center;
margin-bottom: 0;
color: #18191D;
}

.label-first{
    font-style: normal;
font-weight: bold;
font-size: 8px;
line-height: 10px;
display: flex;
align-items: center;
text-align: center;
width: 56.47px;
height: 19.92px;
left: 295px;
top: 822.92px;
justify-content: center;
background: #FFCA05;
border-radius: 3px;

color: #FFFFFF;
}

.label-last{
    font-style: normal;
font-weight: bold;
font-size: 8px;
line-height: 10px;
display: flex;
align-items: center;
text-align: center;
width: 56.47px;
height: 19.92px;
left: 295px;
top: 822.92px;
justify-content: center;
background: #F45D48;
border-radius: 3px;
margin-left: 6px;
color: #FFFFFF;
}

.content-section{
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding-left: 30px;
    padding-right: 30px;
.labelc-content{
    display: flex;
    align-items: center;
}

}




}
}
}


.landing-top-section{
    background-image: url('../Component/logo/background.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
position: relative;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 50vh;   
.div
   {
       width: 100%;
       height: 100%;
       background-color: black;
       opacity: 0.5;
       position: absolute;
       top: 0;
       z-index: 1;
   }
.landing-sub{
    z-index: 2;
    h1{
        font-style: normal;
font-weight: bold;
font-size: 45px;
line-height: 55px;
display: flex;
align-items: center;
text-align: center;
margin-bottom: 3rem;
 color: #FFFFFF;

    }
    .sub{
        display: flex;
        align-items: center;
        // flex-direction: column;
        // justify-content: center;
     
.searchsection{
position: relative;
width: 510px;
height: 60px;
margin-left: 10px;

background: #FFFFFF;
border: 0.5px solid #E5E5E5;
border-radius: 5px;
    img{
    position: absolute;
    top: 23px;
    left: 18px;
}
    input{
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 30px;
        /* or 231% */
        padding-left: 3rem;
        display: flex;
        align-items: center;
        
        color: rgba(24, 25, 29, 0.5);
        border: none;
        height: 100%;
        width: 100%;
        &:focus-visible {
            outline: -webkit-focus-ring-color auto 0px !important;
        }
    }
}

        .drop-down{
            width: 186px;
height: 60px;
display: flex;
position: relative;
align-items: center;
justify-content: center;
background: #FFFFFF;
border: 0.5px solid #E5E5E5;
border-radius: 5px;
            h2{
                font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 30px;
/* or 187% */
margin-left: 7px;
margin-right: 30px;

display: flex;
align-items: center;
margin-bottom: 0;
color: #18191D;

            }
        }
    }
}

}

.holesection{
    height: calc(100vh - 73px);
}


@media only screen and (min-width: 1600px)

{
    
.landing-section
    {

        .top-title-section{
            display: flex;
            justify-content: space-between;
            padding-bottom: 0rem;
            padding-left: 0%;
            padding-right: 4%
        }
        padding-top: 0rem !important;
    padding-bottom: 0rem !important;
        height: calc(50vh - 73px);
        flex-direction: column;
        justify-content: center;
        justify-content: space-evenly;
        /* align-items: center; */
        display: flex;
        padding-left: 4%;
    }
}