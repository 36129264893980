.custom_navbar
{
  height: 73px;
  padding-left: 4%;
  padding-right: 4%;
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 0px solid transparent;
    border-radius: 0rem;
    transition: box-shadow .15s ease-in-out;
    left: 0;
    position: absolute;
    top: 20px;
}
.navbar-expand-lg .navbar-collapse {
   justify-content: end;
}
  nav .navbar-collapse.show {

    }
    
  .navbar-brand{
    transition: 1s;

&:hover{
 
  transform: scale(1.1);
}
  }
  .nav-link
  {
    font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 30px;
/* or 214% */
padding: 0.5rem 2rem;
display: flex;
align-items: center;
transition: 1s;
color: #18191D;
&:hover{
  color: #18191D;
  transform: scale(1.1);
}
  }
  .custom-last-button
  {
    width: 102px;
    height: 36px;
   
    background: #5ABEA0;
    border: 0.5px solid #5ABEA0;
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin-left: 1rem;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: 1s;
    z-index: 20;
    &:hover{
    font-size: 15px;
    background: #5ABEA0;
    border: 0.5px solid #5ABEA0;
    }
    &:after {
      background: #fff;
      content: "";
      height: 155px;
      left: -75px;
      opacity: 1;
      position: absolute;
      top: -50px;
      width: 50px;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
              transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-transform: rotate(35deg);
          -ms-transform: rotate(35deg);
              transform: rotate(35deg);
      z-index: -10;
    }
    
    &:hover:after {
      left: 120%;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
              transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }
    

    
  
    &:active{
      background: #5ABEA0;
      border: 0.5px solid #5ABEA0;
    }
    
    &:focus{
      background: #5ABEA0;
      border: 0.5px solid #5ABEA0;
      box-shadow: 0 0 0 0 #5ABEA0;
    
    }
  }

  .custom-first-buttom{
    width: 102px;
    height: 36px;
 
    background: #1F304F;
    border: 0.5px solid #1F304F;
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    justify-content: center;
color: #FFFFFF;
overflow: hidden;
position: relative;
cursor: pointer;
transition: 1s;
z-index: 20;
&:hover{
font-size: 15px;
background: #1F304F;
border: 0.5px solid #1F304F;
}
&:after {
  background: #fff;
  content: "";
  height: 155px;
  left: -75px;
  opacity: 1;
  position: absolute;
  top: -50px;
  width: 50px;
  -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: rotate(35deg);
      -ms-transform: rotate(35deg);
          transform: rotate(35deg);
  z-index: -10;
}

&:hover:after {
  left: 120%;
  -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

&:active{
  background: #1F304F;
  border: 0.5px solid #1F304F;
}

&:focus{
  background: #1F304F;
  border: 0.5px solid #1F304F;
  box-shadow: 0 0 0 0 #1F304F;

}
  }
}
