.head1 {
    text-align: center;
    padding-top: 4rem;
    color: #fff;
    font-family: arial;
    font-size: 50px;
    font-weight: 400;
  
  }
  
  
  .justichyro
  {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 6rem);
    padding-left: 6%;
    padding-right: 6%;
  }
  
  .headtexttitle{
    font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 50px;
      line-height: 45px;
      display: flex;
      align-items: center;
      margin-bottom: 3%;
      color: #3B3B3B;
  }
  .textpargraph
  {
    font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  margin-top: 3%;
  /* or 187% */
  width: 60%;
  display: flex;
  align-items: center;
  
  color: #3B3B3B;
  }
  .asdfasdz
  {
    font-family: Montserrat;
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF !important;
    width: 100% !important;
    height: 49px;
    /* left: 135px; */
    /* top: 569px; */
    background: #3B3B3B;
    border: 0.5px solid #3B3B3B;
    justify-content: center;
    width: 244px !important;
    margin-top: 7%;
  
  }
  
  .asdfasdzfalse
  {
    font-family: Lato;
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    /* color: #FFFFFF !important; */
    /* width: 100% !important; */
    width: 213px;
    height: 49px;
    /* left: 135px; */
    /* top: 569px; */
    background: white;
    border: 0.5px solid #3B3B3B;
    justify-content: center;
    margin: auto;
    color: #3B3B3B !important;
  
  }
  .navbarsection
  {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    height: 6rem;
    padding-left: 6%;
    border: 1px solid #E5E5E5;
    padding-right: 6%;
  }
  .letschart
  {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #3B3B3B;
    background: #FFFFFF;
    border: 0.5px solid #3B3B3B;
    justify-content: center;
    width: 161px;
    height: 43px;
    cursor: pointer;
    text-decoration: none !important;
  }
  .asdasd
  {
    padding-right: 5%;
    /* height: calc(100vh - 5rem); */
    display: flex;
    /* align-items: center; */
    justify-content: center;
    height: calc(100vh - 6rem);
    padding-left: 6%;
    padding-right: 6%;
    flex-direction: column;
  
  }
  body {
    background-color: #333;
  }
  
  .arrow-holder {
    position: relative;
    height: 50px;
    width: 500px;
  }
  
  .arrow {
    float: left;
    margin-right: 15px;
      width: 0; 
      height: 0; 
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
  }
  
  .arrow-right {
      border-left: 15px solid white;
  }
  
  
  #arrow1 {
    -webkit-animation: flash 2.5s infinite;
  }
  
  #arrow2 {
    -webkit-animation: flash 2.5s infinite .5s;
  }
  
  #arrow3 {
    -webkit-animation: flash 2.5s infinite 1s;
  }
  
  #arrow4 {
    -webkit-animation: flash 2.5s infinite 1.5s;
  }
  
  #arrow5 {
    -webkit-animation: flash 2.5s infinite 2s;
  }
  @-webkit-keyframes flash {
    0%   { opacity: 1; }
    100% { opacity: 0; }
  }
  .textsakd
  {
    font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin: 0;
  padding-right: 11px;
  color: #3B3B3B;
  
  }
  .oasmdlkamsldkas
  {
    display: flex;
    align-items: center;
    margin-top: 4%;
  }
  
  .watchvideo
  {
  
    cursor: pointer;
    position: relative;
    -webkit-animation: mover 1s infinite  alternate;
      animation: mover 1s infinite  alternate;
      padding-left: 2rem;
  }
  
  @-webkit-keyframes mover {
    0% { transform: translateX(10px); }
    100% { transform: translateX(0px); }
  }
  @keyframes mover {
    0% { transform: translateX(10px); }
    100% { transform: translateX(0px); }
  }
  
  .letschart:hover
  {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #3B3B3B;
    background: #FFFFFF;
    border: 0.5px solid #3B3B3B;
    justify-content: center;
    width: 161px;
    height: 43px;
    cursor: pointer;
    text-decoration: none !important;
  }
  .player_video
  {
    /* width:auto !important;
     height:auto !important;   */
     border: 1px solid #efebebd9;
   
  }
  .readytoplay
  {
    background-color: #fff ;
    color: #09355d ;
    font-weight: 700 ;
    height: 3rem;
  }
  :focus
  {
    border: none !important;
    outline-style: none !important;
  }
  .player_video
  {
    /* margin: auto; */
  }
  .head2 {
    text-align: center;
    color: #fff;
    font-family: arial;
    font-size: 70px;
    font-weight: 700;
    padding-bottom: 2rem;
   
  }
  .homesecfp
  {
    height: 100vh;
    display: flex;
      justify-content: center;
      align-items: center;
  }
  .headtext {
    text-align: center;
    color: #fff;
    font-family: arial;
    font-size: 70px;
    font-weight: 700;
  
  
  }
  .btn-clas
  {
    height: 5rem;
    background: white;
    color: #09355d;
    font-weight: 700;
    width: 11rem;
  }
  .btn-clas:hover{
    background-color: #09355d !important;
    color: white;
  }
  .para {
    text-align: center;
    font-family: arial;
    font-size: 2vh;
    font-weight: 400;
  margin: 0;
    color: #fff;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .if
  {
    width:640px;
     height:360px; 
  }
  .divsection{
    display: none;
  }
  body
  {
  background-color: white;
    background-size: cover;
  
    
  
  }
  .subsechome
  {
    text-align: center;
  }
  .modal-header
  {
    background-color: #09355d !important;
   color: #fff !important;
  }
  .modal-header .close {
    color: #fff !important;
  }
  
  .close:hover {
    color: #fff !important;
    text-decoration: none;
  }
  .popupbutton
  {
    background-color: #fff !important;
      color: #09355d !important;
      font-weight: 700 !important;
      height: 4rem;
  }
  .next
  {
    background-color: #09355d  !important;
      color: white !important;
    
  }
  .homesecfb
  {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4rem;
  }
  .homesec
  {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4rem;
  
  }
  .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 500px;
    margin: 0;
  
    transform: translate(-50%, -50%) !important;
  }
  .mobileversion
  {
    display: none;
  }
  .asdasrww3
  {
    display: none;
  }
  .mobileshowetile
  {
    display: none;
  }
  .mobilehead1
  {
    display: none;
  }
  .iuwrheg
  {
    display: none;
  }
  .ntjasiud
  {
    background: #3B3B3B;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #FFFFFF;
      height: 49px;
      padding-left: 2rem;
      padding-right: 2rem;
    
  }
  .dafsfk
  {
    padding-right: 12px;
  }
  .ntjasiusd
  {
    
    color: #3B3B3B;
    height: 49px;
    margin-bottom: 0!important;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }
  @media only screen and (min-width:0) and (max-width:650px)
  {
    
    .if
    {
      width:100%;
      height: auto;
    }
    .modal-dialog {
      position: absolute;
      top: 50%;
      left: 50%;
      width:98%;
      margin: 0;
    
      transform: translate(-50%, -50%) !important;
    }
  }
  @media only screen and (min-width:0) and (max-width:767.9px)
  {
    .mobileshowetile
    {
      display: block;
    }
    .iuwrheg
    {
      display: block !important;
    }
    .justichyro
  {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 6rem);
    padding-left: 0%;
    padding-right: 0%;
  }
  .letschart
  {
  display: block;
  
  }
  .adsadsasdax
  {
    display: block;
  }
  .divsection
  {
    display: block;
  }
  .headtexttitle{
    display: block;
  }
  
  .zzzzzzzzzzzzzz
    {
      font-family: Lato;
      cursor: pointer;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      /* color: #FFFFFF !important; */
      /* width: 100% !important; */
      width: 213px;
      height: 49px;
      /* left: 135px; */
      /* top: 569px; */
      background: white;
      border: 0.5px solid #3B3B3B;
      justify-content: center;
      margin: auto;
      background: #3B3B3B !important;
      color: white !important;
    }
  .mobilerow
    {
      display: flex;
    
      height: calc(100vh - 5rem);
      justify-content: space-evenly
    }
    .justichyro {
      display: block;
      height: auto;
    }
  .textpargraph
  {
    display: none !important;
  }
  .asdasd
  {
    height: auto;
  }
  .asdasrww3
  {
    display: block;
  }
  .ada{
    display:none;
  }
  .ittime {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #3B3B3B;
    justify-content: center;
  }
  .react-player__preview
  {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  
  }
    .player_video {
      width: 100% !important;
      height:40vh  !important;
      margin-top: 0 !important;
      margin-bottom:0 !important;
      background: black;
  }
  .asdfasdz
  {
    margin-top: 0;
    width: 100%;
  }
  .sgsfg
  {
    margin-bottom: 0 !important;
  
  }
  .divsection
  {
  
    text-align: center;
  
  }
  .mobileversion
  {
    height: calc(100vh - 5rem);
  }
  .mobileversion {
    height: calc(100vh - 5rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .textpargraph {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #3B3B3B;
    margin-bottom: 2rem;
    text-align: center;
    width: 100%;
    margin-top: 2rem;
  }
    .letschart
  {
  display: none;
  
  }
  .adsadsasdax
  {
    display: none;
  }
  .divsection
  {
    display: block;
  }
  .headtexttitle{
    display: none;
  }
  
  .mobilehead1{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #3B3B3B;
    padding-left: 6%;
    padding-right: 6%;
    justify-content: center;
    margin-bottom: 0;
  }
  .headtexttitlemobile {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 45px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;;
  
    color: #3B3B3B;
  }
  .mobiletext
  {
    font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding-top: 1rem;
  color: #3B3B3B;
  
  }
  .navbarsection {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    height: 5rem;
    padding-left: 4%;
   
    padding-right: 4%;
    background: white;
  }
  .wwr
  {
    display: none;
  }
  
  
  .head1 {
    font-size: 24px;
    padding-top: 0;
   line-height: auto;;
  }
  .headtext {
    font-size: 24px;
  
   line-height: auto;;
  
  
  }
  .homesecfb {
   
    padding-bottom: 4rem;
    padding-top: 4rem;
  }
  .btn-clas {
    height: 4rem;
    margin-bottom: 25px;
  width: 100%;
  }
  .homesec {
    height: 100vh;
    padding-bottom: 0rem;
  }
  .head2 {
   
      font-size: 24px;
      padding-bottom: 1rem;
      line-height: auto;
  }
  .para {
    text-align: center;
    font-family: arial;
    font-size: 16px;
  }
  .popupbutton {
    height: 3rem;
    font-size: 14px;
  }
  }
  @media only screen and (min-width:768px) and (max-width:1350px)
  {
  
    
  }
  
  
  @media only screen and (min-width:1550px) and (max-width:5000px)
  {.asdasd
    {
      padding-left: 7.5%;
      padding-right: 7.5%;
    }
    .navbarsection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: white;
      height: 6rem;
      padding-left: 7.5%;
      border: 1px solid #E5E5E5;
      padding-right: 7.5%;
  }
  .justichyro {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 6rem);
    padding-left: 7.5%;
    padding-right: 7.5%;
  }
    .homesecfb
    {
      display: flex;
      justify-content: center;
      height: 100vh;
      padding-bottom: 0rem;
    }
  
    .homesec {
      height: 100vh;
      padding-bottom: 0rem;
    }
    .head1 {
  
      padding-top: 0;
     line-height: auto;;
    }
  }
  
  @media screen and (min-device-width : 0px) and (max-device-width : 767.9px) and (orientation : landscape)
  {
    .asdasd {
      padding-right: 5%;
       height: 100%; 
      display: flex;
      /* align-items: center; */
      justify-content: center;
      height: auto;
      padding-left: 6%;
      padding-right: 6%;
      flex-direction: column;
  }
  }
  